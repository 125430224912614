import * as React from 'react';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import PropTypes from 'prop-types';
import { DropdownMenu } from '../../DropdownMenu';

const StyledContent = styled('div')(({ theme }) => ({
  minWidth: 300,
  [theme.breakpoints.up('md')]: {
    minWidth: 450,
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: 600,
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: 750,
  },
}));

const StyledDropdown = styled('div')(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: '65%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '55%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '45%',
  },
  alignSelf: 'center',
}));

CalendarStep.propTypes = {
  report: PropTypes.object,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

const CALENDAR_STATE = {
  date: '',
  type: 'IN_PERSON',
};

const OPTIONS = [
  { value: 'IN_PERSON', label: 'Célula Presencial' },
  { value: 'ONLINE', label: 'Célula Online' },
  { value: 'NONE', label: 'Não Houve Célula' },
];

export default function CalendarStep(props) {
  const [calendarState] = React.useState(CALENDAR_STATE);
  const [date, setDate] = React.useState(calendarState.date);
  const [type, setType] = React.useState(calendarState.type);

  React.useEffect(() => {
    if (props.report !== null) {
      setDate(props.report.date);
      if (props.report.type !== undefined) {
        setType(props.report.type === '' ? 'IN_PERSON' : props.report.type);
      }
    }
  }, [props]);

  const handleChange = (input, value) => {
    if (props.readOnly) {
      return;
    }

    if (input === 'date') {
      setDate(dayjs(value));
    } else {
      setType(value);
    }
    calendarState[input] = value;
    props.onChange(input, value);
  };

  return (
    <>
      <StyledContent>
        <Stack direction="column" spacing={4} sx={{ alignItems: 'center' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              value={date !== '' ? dayjs(date) : null}
              views={['month', 'day']}
              disabled={props.readOnly}
              onChange={(newValue) => handleChange('date', newValue)}
            />
          </LocalizationProvider>

          <StyledDropdown>
            <DropdownMenu items={OPTIONS} defaultValue={OPTIONS.find((e) => e.value === type)} readOnly={props.readOnly} onChange={(newValue) => handleChange('type', newValue)} />
          </StyledDropdown>
        </Stack>
      </StyledContent>
    </>
  );
}
