export const mapMeetingTypeToLabel = (type) => {
    switch (type) {
      case 'NONE':
        return 'Não houve célula';
      case 'ONLINE':
        return 'Célula online';
      default:
        return 'Célula presencial';
    }
  };

  export const mapMood = (mood) => {
    switch (mood) {
      case 'SAD':
        return '🙁';
      case 'HAPPY':
        return '😊';
      default:
        return '😐';
    }
  }