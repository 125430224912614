import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Divider, Typography } from '@mui/material';
import { fCurrencyWithLocale } from '../../utils/formatNumber';

const StyledContent = styled('div')(({ theme }) => ({
  borderRadius: '15px',
  border: `solid 2px ${theme.palette.background.default}`,
  bgcolor: theme.palette.background.paper,
  ":hover": {
    bgcolor: theme.palette.background.default
  },
  padding: theme.spacing(2),
  margin: theme.spacing(1),
  minWidth: '20%',
  [theme.breakpoints.down('md')]: {
    minWidth: '50%',
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '80%',
  }
}));

const SubscriptionTier = ({ item }) => (
  <StyledContent>
    <Typography variant="h4">{fCurrencyWithLocale(item.price / 100, item.currency)} por mês</Typography>
    <Divider sx={{ my: 2 }} />
    <Typography variant="body1" paragraph>{item.maxAmount >= 0 ? `Até ${item.maxAmount} células` : 'Ilimitado'}</Typography>
    <Typography variant='body1' paragraph>Gerenciamento de células</Typography>
    <Typography variant="body1" paragraph>Número ilimitado de supervisões</Typography>
    <Typography variant="body1" paragraph>Número ilimitado de membros</Typography>
  </StyledContent>
);

SubscriptionTier.propTypes = {
  item: PropTypes.object
};

export default SubscriptionTier;
