import React from 'react';
import PropTypes from 'prop-types';

import { useLocalStorage } from '@uidotdev/usehooks';
import { Container, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useNetwork from '../../hooks/useNetwork';
import { fCurrencyWithLocale } from '../../utils/formatNumber';
import CustomDialog from '../../components/CustomDialog';

const SubscribedContent = ({ subscription, onCancel }) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [cancelLoading, setCancelLoading] = React.useState(false);
  const [organizationId] = useLocalStorage('organizationId', '');
  const { request } = useNetwork();

  const onDialogAction = React.useCallback(async (positive) => {
    setOpenDialog(false);

    if (positive) {
      try {
        await request(`v1/subscriptions/${subscription.id}`, 'DELETE');
        onCancel();
      } catch (error) {
        console.log(error);
      } finally {
        setCancelLoading(false);
      }
    } else {
      setCancelLoading(false);
    }
  }, [subscription, request, onCancel]);

  const cancelSubscription = () => {
    setCancelLoading(true);
    setOpenDialog(true);
  };

  const updateSubscription = React.useCallback(async () => {
    setUpdateLoading(true);
    try {
      const { data } = await request(`v1/subscriptions/${subscription.id}`, 'PUT', { organizationId });
      window.open(data.redirectUrl, '_self');
    } catch (error) {
      console.log(error);
    } finally {
      setCancelLoading(false);
    }
  }, [subscription, request, organizationId]);

  return (
    <>
      <Typography variant="h3" sx={{ mb: 5 }}>Plano e pagamento</Typography>

      <Typography variant="body1">{subscription.productName}</Typography>
      <Typography variant="h4" sx={{ mb: 5 }}>{fCurrencyWithLocale(subscription.price / 100, subscription.currency)}</Typography>

      <Typography variant="body1">{`Sua assinatura começou no dia ${subscription.subscriptionDate}. A data do próximo pagamento é ${subscription.nextPayment}. Você pode utilizar até ${subscription.maxAmount} células, e atualmente você está utilizando ${subscription.currentAmount} células. Aproveite ao máximo sua assinatura e continue a explorar todas as funcionalidades disponíveis!`}</Typography>

      <Container sx={{ display: 'flex', justifyContent: 'end', mt: 5 }}>
        <Stack direction="row" spacing={1}>
          <LoadingButton
            size="large"
            variant="contained"
            color="primary"
            loading={updateLoading}
            onClick={() => updateSubscription()}
            sx={{ minWidth: '150px', display: 'flex', justifySelf: 'center' }}>
            Atualizar pagamento
          </LoadingButton>

          <LoadingButton
            size="large"
            variant="contained"
            color="error"
            loading={cancelLoading}
            onClick={() => cancelSubscription()}
            sx={{ minWidth: '150px', display: 'flex', justifySelf: 'center' }}>
            Cancelar assinatura
          </LoadingButton>
        </Stack>
      </Container>

      <CustomDialog
        title="Confirmação de Cancelamento de Assinatura"
        content="Você realmente deseja cancelar sua assinatura? Esta ação encerrará seu acesso às funcionalidades premium do aplicativo."
        open={openDialog}
        onAction={(positive) => onDialogAction(positive)}
        onClose={() => {
          setCancelLoading(false);
          setOpenDialog(false)
        }}
        positiveAction="Sim"
        negativeAction="Não" />
    </>
  );
};

SubscribedContent.propTypes = {
  subscription: PropTypes.array,
  onCancel: PropTypes.func,
};

export default SubscribedContent;