import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

const useAuth = () => {
  const {
    isAuthenticated: isAuthKinde,
    login: loginKinde,
    logout: logoutKinde,
    getToken: getTokenKinde,
    user: userKinde,
    getFlag: getFlagKinde,
    isLoading: isLoadingKinde,
    getPermission
  } = useKindeAuth()

  const isAuthenticated = isAuthKinde;

  const isLoading = isLoadingKinde;

  const isAdmin = !isLoading && (getPermission('manage:organization')?.isGranted ?? false);

  const user = userKinde;
  
  const login = () => loginKinde();

  const logout = (params) => logoutKinde(params);

  const getToken = () => getTokenKinde();

  const getFlag = (key, defaultValue) => getFlagKinde(key, defaultValue);

  return { isAuthenticated, isLoading, isAdmin, user, login, logout, getToken, getFlag }
}

export default useAuth;