import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '@uidotdev/usehooks';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import SupergroupOverviewTab from '../sections/supergroup/SupergroupOverviewTab';
import useAuth from '../hooks/useAuthentication';

const StyledContent = styled('div')(({ theme }) => ({
  minWidth: 300,
  [theme.breakpoints.up('md')]: {
    minWidth: 450,
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: 600,
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: 750,
  },
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4, 0),
}));

export const SupergroupOverviewPage = ({ group, setupButton }) => {
  const [me] = useLocalStorage('me', {});
  const [groupId, setGroupId] = React.useState('');

  const { isAuthenticated, isLoading } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigate('/login');
    }

    if (isAuthenticated) {
      if (group !== null) {
        setGroupId(group.id);
      } else if (me.hasAccess) {
        setGroupId(me.group.id);
      } else {
        navigate('/no-access');
      }
    }

    setupButton({ action: { mode: 'create', key: 'group', parentId: groupId }, label: 'Célula' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading]);

  return (
    <>
      <Helmet>
        <title>CélulaHub</title>
      </Helmet>

      <Container>
        <SnackbarProvider />

        <StyledContent sx={{ textAlign: 'center', alignItems: 'top' }}>
          <SupergroupOverviewTab groupId={groupId} key={groupId} />
        </StyledContent>
      </Container>
    </>
  );
}

SupergroupOverviewPage.propTypes = {
  group: PropTypes.object,
  setupButton: PropTypes.func
}