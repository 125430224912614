import useNetwork from "./useNetwork";

// TODO: bring local storage handling here and just output me data
export const useMe = () => {
  const { request } = useNetwork();

  const getMe = async (email) => {
    try {
      const { data } = await request(`v1/people?email=${encodeURIComponent(email)}`, 'GET');
      return { data, error: undefined };
    } catch (error) {
      console.log(`error: ${JSON.stringify(error)}`);
      return { data: undefined, error };
    }
  }

  return { getMe };
}