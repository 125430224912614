import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import useAuth from '../hooks/useAuthentication';

const MENU_ADMIN = [
  {
    id: 'home',
    label: 'Home',
    icon: 'eva:home-fill',
  },
  {
    id: 'plan',
    label: 'Plano e pagamento',
    icon: 'eva:credit-card-fill',
  },
  {
    id: 'feedback',
    label: 'Enviar feedback',
    icon: 'eva:settings-2-fill',
  },
];

const MENU = [
  {
    id: 'home',
    label: 'Home',
    icon: 'eva:home-fill',
  },
  {
    id: 'feedback',
    label: 'Enviar feedback',
    icon: 'eva:settings-2-fill',
  },
]

AccountPopover.propTypes = {
  triggerModal: PropTypes.func
}

export default function AccountPopover(props) {
  const [open, setOpen] = useState(null);
  const { isAuthenticated, user, logout, isAdmin } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (event) => {
    setOpen(null);

    switch (event) {
      case 'home':
        navigate('/');
        break;
      case 'profile':
        props.triggerModal('profile');
        break;
      case 'plan':
        navigate('/subscription');
        break;
      case 'feedback':
        props.triggerModal('feedback');
        break;
      case 'logout':
        logout();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar sx={{ bgcolor: theme.palette.primary.main, fontSize: '11pt', fontWeight: 'bold' }}>
          {isAuthenticated && user.given_name && user.family_name && `${user.given_name.charAt(0)}${user.family_name.charAt(0)}`}
        </Avatar>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ p: 1 }}>
          <MenuItem key='profile' onClick={() => handleClose('profile')}>
            <Typography variant="subtitle2" noWrap>
              {isAuthenticated && user.given_name && `${user.given_name} ${user.family_name}`}
              {isAuthenticated && !user.given_name && 'Atualizar perfil'}
            </Typography>
          </MenuItem>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {!isAdmin && MENU.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClose(option.id)}>
              {option.label}
            </MenuItem>
          ))}
          {isAdmin && MENU_ADMIN.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClose(option.id)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={() => handleClose('logout')} sx={{ m: 1 }}>
          Sair
        </MenuItem>
      </Popover>
    </>
  );
}
