import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useLocalStorage } from '@uidotdev/usehooks';
import PropTypes from 'prop-types';

import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Button, CssBaseline, Toolbar } from '@mui/material';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import Iconify from '../../components/iconify';
import Logo from '../../components/logo';
import AccountPopover from '../AccountPopover';
import FeedbackModal from '../FeedbackModal';
import PersonDetailModal from '../../pages/PersonDetailModal';
import { GroupDetailModal } from '../../pages/GroupDetailModal';
import ReportDetailModal from '../../pages/ReportDetailModal';
import useAuth from '../../hooks/useAuthentication';
import CustomDialog from '../../components/CustomDialog';

const StyledButton = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

const StyledLogo = styled('div')(() => ({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)'
}));

SimpleLayout.propTypes = {
  button: PropTypes.object,
  shouldUpdate: PropTypes.func,
};

export default function SimpleLayout(props) {
  const { isAuthenticated, isAdmin, isLoading, user } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();

  const [me] = useLocalStorage('me', {});
  const [rootId] = React.useState(() => me.rootId);
  const [hasAccess] = React.useState(() => me.hasAccess);

  const [profile, setProfile] = React.useState({ open: false, force: false });
  const [person, setPerson] = React.useState({ open: false, mode: 'create', parentId: '', person: {} });
  const [group, setGroup] = React.useState({ open: false, mode: 'create', parentId: '', group: {} });
  const [report, setReport] = React.useState({ open: false, mode: 'create', parentId: '', report: {} });

  const [key, setKey] = React.useState('');
  const [feedback, setFeedback] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);

  const handleClose = (key, success, message) => {
    switch (key) {
      case 'profile':
        setProfile({ open: false });
        break;
      case 'person':
        setPerson({ ...person, open: false });
        break;
      case 'report':
        setReport({ ...report, open: false });
        break;
      case 'group':
        setGroup({ ...group, open: false });
        break;
      case 'feedback':
        setFeedback(false);
        break;
      default:
        break;
    }

    if (success !== undefined) {
      if (success) {
        props.shouldUpdate(key);
      }

      if (message !== undefined) {
        showSnackbar(success, message);
      }
    }
  }

  const showSnackbar = (success, message) => {
    enqueueSnackbar(message, {
      variant: success === true ? 'success' : 'error',
      preventDuplicate: true,
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    });

  }

  const onDialogDismiss = () => {
    setDialog(false);

    if (isAdmin) {
      navigate('/subscription');
    }
  }

  React.useEffect(() => {
    if (props.button.action === undefined) {
      return;
    }

    setKey(props.button.action.key);
    switch (key) {
      case 'person':
        setPerson({ ...person, mode: props.button.action.mode, parentId: props.button.action.parentId, person: props.button.action.row });
        break;
      case 'report':
        setReport({ ...report, mode: props.button.action.mode, parentId: props.button.action.parentId, report: props.button.action.row });
        break;
      case 'group':
        setGroup({ ...group, mode: props.button.action.mode, parentId: props.button.action.parentId, group: props.button.action.row });
        break;
      default:
        break;
    }

    if (isAuthenticated && user.given_name === undefined) {
      onAction('profile', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.button.action, key, isAuthenticated]);

  const onAction = (option, force = false) => {
    if (typeof option !== "string") {
      option = key;
    }

    switch (option) {
      case 'profile':
        setProfile({ open: true, force });
        break;
      case 'person':
        setPerson({ ...person, open: true });
        break;
      case 'report':
        setReport({ ...report, open: true });
        break;
      case 'group':
        if (!hasAccess) {
          setDialog(true);
        } else {
          setGroup({ ...group, open: true });
        }
        break;
      case 'feedback':
        setFeedback(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <CssBaseline />
      <SnackbarProvider />
      <AppBar position='fixed' elevation={0}>
        <Toolbar sx={{ backgroundColor: theme.palette.grey[100], justifyContent: isAuthenticated ? 'space-between' : 'center' }}>
          {isAuthenticated && <AccountPopover triggerModal={(key) => onAction(key)} />}

          <StyledLogo>
            <Logo />
          </StyledLogo>

          {isAuthenticated && props.button.label && <StyledButton>
            <Button variant='contained' startIcon={<Iconify icon='eva:plus-fill' />} onClick={onAction}>
              {props.button.label}
            </Button>
          </StyledButton>}
        </Toolbar>
      </AppBar>

      <Toolbar />

      <FeedbackModal open={feedback} onClose={(success, message) => handleClose('feedback', success, message)} />
      <PersonDetailModal mode={person.mode} person={person.person} open={person.open} force={false} onClose={(success, message) => handleClose('person', success, message)} />
      {!isLoading && isAuthenticated && <PersonDetailModal mode='fetch' person={user.email} open={profile.open} force={profile.force} onClose={(success, message) => handleClose('profile', success, message)} />}
      <GroupDetailModal mode={group.mode} parentId={rootId} group={group.group} open={group.open} onClose={(success, message) => handleClose('group', success, message)} />
      <ReportDetailModal mode={report.mode} report={report.report} groupId={report.parentId} open={report.open} onClose={(success, message) => handleClose('report', success, message)} />
      <CustomDialog
        open={dialog}
        title="Assinatura Necessária"
        content={
          `Sua organização não possui uma assinatura ativa no momento. Para realizar essa ação, é necessário ter uma assinatura ativa. ${isAdmin
            ? "Por favor, assine para continuar."
            : "Por favor, entre em contato com o administrador para solicitar uma assinatura."}`
        }
        onAction={() => onDialogDismiss()}
        onClose={() => setDialog(false)}
        positiveAction="Ok"
      />

      <Outlet />
    </>
  );
}
