import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Tab, Tabs } from '@mui/material';
import OrganizationOverviewTab from './OrganizationOverviewTab';
import OrganizationGroupListTab from './OrganizationGroupListTab';
import OrganizationMembersTab from './OrganizationMembersTab';

const StyledContent = styled('div')(({ theme }) => ({
  minWidth: 300,
  [theme.breakpoints.up('md')]: {
    minWidth: 450,
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: 600,
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: 750,
  },
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
}));

OrganizationOverviewPage.propTypes = {
  id: PropTypes.string,
  setupButton: PropTypes.func
}

export default function OrganizationOverviewPage(props) {
  const [tab, setTab] = React.useState(0);

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  const handleChange = (_, value) => {
    setTab(value);
  };

  React.useEffect(() => {
    props.setupButton({ action: { mode: 'create', key: tab === 2 ? 'person' : 'group', parentId: props.id }, label: tab === 2 ? 'Participante' : 'Célula' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return (
    <>
      <Helmet>
        <title>CélulaHub</title>
      </Helmet>
      <StyledContent>
        <Tabs value={tab} onChange={handleChange}>
          <Tab label="Visão geral" {...a11yProps(0)} />
          <Tab label="Supervisões" {...a11yProps(1)} />
          <Tab label="Participantes" {...a11yProps(2)} />
        </Tabs>

        {tab === 0 && <OrganizationOverviewTab id={props.id} key={props.id} />}
        {tab === 1 && <OrganizationGroupListTab id={props.id} key={props.id} />}
        {tab === 2 && <OrganizationMembersTab id={props.id} key={props.id} />}
      </StyledContent>
    </>
  );
}
