import * as React from 'react';
import PropTypes from 'prop-types';
import { TextField, Typography } from '@mui/material';
import useAuth from '../hooks/useAuthentication';
import CustomModal from '../components/CustomModal';
import { DropdownMenu } from '../sections/DropdownMenu';

FeedbackModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
}

const FEEDBACK_OPTIONS = [
  { value: 'GENERAL_FEEDBACK', label: 'Feedback geral' },
  { value: 'QUESTION', label: 'Pergunta' },
  { value: 'BUG_REPORT', label: 'Reportar bug' },
  { value: 'FEATURE_REQUEST', label: 'Solicitar funcionalidade' },
];

export default function FeedbackModal(props) {
  const [option, setOption] = React.useState(FEEDBACK_OPTIONS[0].label);
  const [content, setContent] = React.useState('');
  const { user } = useAuth();

  const getLabelFromOption = () => {
    switch (option) {
      case 'QUESTION':
        return 'Question';
      case 'BUG_REPORT':
        return 'Bug Report';
      case 'FEATURE_REQUEST':
        return 'Feature Request';
      default:
        return 'General Feedback';
    }
  }

  const onComplete = React.useCallback(async () => {
    if (content === '') {
      props.onClose();
      return;
    }

    try {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user_name: `${user.given_name} ${user.family_name}`,
          user_email: user.email,
          type: getLabelFromOption(),
          content
        }),
      };

      await fetch('https://eoclmvlf74papmb.m.pipedream.net', options);

      props.onClose(true, 'Feedback enviado com sucesso');
    } catch (error) {
      console.log(error);
      setContent('');
      props.onClose(false, 'Não foi possível enviar o feedback');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, user, content, option]);

  return (
    <>
      <CustomModal
        title='Feedback'
        action='Enviar'
        open={props.open}
        onClose={() => props.onClose()}
        onAction={onComplete}
      >
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Envie sugestões, críticas, elogios ou reporte bugs. Sua experiência é fundamental para aprimorarmos nossa plataforma e torná-la ainda melhor.
        </Typography>
        <DropdownMenu
          items={FEEDBACK_OPTIONS}
          defaultValue={FEEDBACK_OPTIONS[0]}
          onChange={(newValue) => setOption(newValue)}
        />
        <TextField
          id="content-field"
          placeholder="Compartilhe sua opinião e sugestões aqui"
          multiline
          fullWidth
          rows={8}
          sx={{ my: 4 }}
          value={content}
          onChange={(event) => setContent(event.target.value)}
        />
      </CustomModal>
    </>
  );
}