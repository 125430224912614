import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Collapse, Grid, TableCell, TableRow } from '@mui/material';

import SummaryBox from '../reports/overview/SummaryBox';

import { fDate } from '../../utils/formatTime';
import { groupTypeToString, weekdayToString } from '../../utils/GroupUtil';
import { mapMood } from '../reports/overview/MeetingUtil';

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    leader: PropTypes.string,
    type: PropTypes.string,
    nextMultiplication: PropTypes.string,
    mood: PropTypes.string,
    weekday: PropTypes.string,
    members: PropTypes.number,
  }).isRequired,
};

export default function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleMoreDetailsClick = (row) => {
    navigate('/overview', { state: { row, role: row.type === 'SUPERGROUP' ? 'SUPERVISOR' : 'LEADER', mode: 'edit' } });
  };

  const labelId = `groups-${row.id}`;

  return (
    <>
      <TableRow hover onClick={() => toggleOpen()} tabIndex={-1} key={`${row.id}`} sx={{ cursor: 'pointer' }}>
        <TableCell component="th" id={labelId} scope="row">
          {row.name}
        </TableCell>
        <TableCell id={labelId} scope="row">
          {row.leader !== null ? row.leader : 'Sem líder'}
        </TableCell>
        <TableCell id={labelId} scope="row">
          {groupTypeToString(row.type)}
        </TableCell>
        <TableCell id={labelId} scope="row">
          {row.nextMultiplication !== null ? fDate(row.nextMultiplication) : 'A definir'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 2 }}>
              <Grid container spacing={3} sx={{ mt: 0 }}>
                <Grid item xs={6} md={3}>
                  <SummaryBox title="Dia da semana" content={weekdayToString(row.weekday)} variant="compact" />
                </Grid>
                <Grid item xs={6} md={3}>
                  <SummaryBox title="Membros" content={row.members} variant="compact" />
                </Grid>
                <Grid item xs={6} md={3}>
                  <SummaryBox title="Presença de Deus" content={mapMood(row.mood)} variant="compact" />
                </Grid>
                <Grid item xs={6} md={3}>
                  <SummaryBox title="Mais detalhes" content="+" variant="compact" onClick={() => handleMoreDetailsClick(row)} />
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
