import * as React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import CustomModal from '../components/CustomModal';
import useNetwork from '../hooks/useNetwork';

OrganizationDetailModal.propTypes = {
  organizationId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default function OrganizationDetailModal(props) {
  const [name, setName] = React.useState('');
  const { request } = useNetwork();

  const handleChange = (input, value) => {
    switch (input) {
      case 'name':
        setName(value);
        break;
      default:
        break;
    }
  };

  const onFetch = React.useCallback(async () => {
    if (props.organizationId !== undefined) {
      request(`v1/organizations/${props.organizationId}`, 'GET').then((response) => {
        const { data } = response;
        if (data !== null && data !== undefined) {
          setName(data.name);
        }
      }).catch((error) => {
        console.log(`error: ${error}`);
      });
    }
  }, [request, props]);

  React.useEffect(() => {
    if (props.open) {
      onFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const onComplete = React.useCallback(async () => {
    try {
      await request(`v1/organizations/${props.organizationId}`, 'PUT', { name });
      props.onClose(true);
    } catch (error) {
      console.log(`error: ${JSON.stringify(error)}`);
      props.onClose(false);
    }
  }, [name, props, request]);

  return (
    <>
      <CustomModal
        title='Editar organização'
        action='Salvar'
        open={props.open}
        onClose={props.onClose}
        onAction={onComplete}>
        <TextField
          id="filled-basic"
          fullWidth
          label="Nome"
          variant="outlined"
          value={name}
          onChange={(newValue) => handleChange('name', newValue.target.value)}
        />
      </CustomModal>
    </>
  );
}