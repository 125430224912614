import * as React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { Container, Grid } from '@mui/material';
import useNetwork from '../../../hooks/useNetwork';

import GroupFrequency from './GroupFrequency';
import SummaryBox from './SummaryBox';
import GroupTimeline from './GroupTimeline';

import { mapMeetingTypeToLabel, mapMood } from './MeetingUtil';
import { fDate } from '../../../utils/formatTime';
import { GroupDetailModal } from '../../../pages/GroupDetailModal';
import { GroupInfo } from '../../../components/GroupInfo';

const StyledContent = styled('div')(({ theme }) => ({
  minWidth: 300,
  [theme.breakpoints.up('md')]: {
    minWidth: 450,
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: 600,
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: 750,
  },
  justifyContent: 'start',
  textAlign: 'start',
}));

const DATA_STATE = {
  group: {
    name: '',
    nextMultiplication: '',
  },
  mood: '',
  lastMeetings: [],
};

OverviewTab.propTypes = {
  groupId: PropTypes.string
}

export default function OverviewTab(props) {
  const [open, setOpen] = React.useState(false);
  const [shouldUpdate, setShouldUpdate] = React.useState(true);
  const [data, setData] = React.useState(DATA_STATE);
  const { request } = useNetwork();

  React.useEffect(() => {
    if (shouldUpdate) {
      if (props.groupId !== undefined && props.groupId !== null && props.groupId !== '') {
        request(`v1/groups/${props.groupId}/overview`, 'GET').then((response) => {
          setData(response.data);
        });
      }

      setShouldUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdate]);

  const onClose = (updated) => {
    setShouldUpdate(updated);
    setOpen(false);
  };

  const attendanceData = {
    name: 'Presentes',
    type: 'line',
    fill: 'solid',
    data: data !== undefined ? data.lastMeetings.map((meeting) => meeting.attendance) : [],
  };

  return (
    <>
      <StyledContent>
        <Container maxWidth="xl" sx={{ mt: 4 }}>
          <GroupInfo name={data !== undefined ? data.group.name : ''} onClick={() => setOpen(true)} />
          
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <SummaryBox
                title="Próxima Multiplicação"
                content={data !== undefined && data.group.nextMultiplication !== null ? fDate(data.group.nextMultiplication) : 'A definir'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SummaryBox title="Presença de Deus" content={mapMood(data !== undefined ? data.mood : '')} />
            </Grid>
            <Grid item xs={12} md={4}>
              <GroupTimeline
                title="Últimas Células"
                list={data !== undefined ? data.lastMeetings.map((row) => ({
                  id: crypto.randomUUID(),
                  title: mapMeetingTypeToLabel(row.type),
                  type: row.type,
                  time: row.date,
                })) : []}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <GroupFrequency
                title="Frequência dos membros"
                chartLabels={data !== undefined ? data.lastMeetings.map((meeting) => meeting.date) : []}
                chartData={[attendanceData]}
              />
            </Grid>
          </Grid>
        </Container>
      </StyledContent>
      <GroupDetailModal mode='edit' group={data.group} open={open} onClose={(updated) => onClose(updated)} />
    </>
  );
}
