import { useRef, useState } from 'react';
import axios from 'axios';
import useAuth from './useAuthentication';

const client = axios.create({
    baseURL: process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.celulahub.com' : 'https://api-staging.celulahub.com',
});
client.defaults.timeout = 5000;

const useNetwork = () => {
    const { getToken } = useAuth();
    const [loading, setLoading] = useState(true);
    const controllerRef = useRef(new AbortController());
    const cancel = () => {
        controllerRef.current.abort();
    };

    const request = async (url, method, payload = null) => {
        console.log(`request, method: ${method}, url: ${url}`);
        try {
            const token = await getToken();
            const headers = {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            };

            const response = await client.request({
                data: payload,
                signal: controllerRef.current.signal,
                method,
                url,
                headers,
            });

            return response;
        } catch (err) {
            throw err.response.data;
        } finally {
            setLoading(false);
        }
    };

    return { loading, request, cancel };
};

export default useNetwork;