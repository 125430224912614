// @mui
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(32),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

const StyledIconCompact = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(16),
  height: theme.spacing(4),
  justifyContent: 'center',
  marginBottom: theme.spacing(1),
}));

SummaryBox.propTypes = {
  content: PropTypes.any,
  additionalContent: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  sx: PropTypes.object,
  variant: PropTypes.string,
};

export default function SummaryBox({
  title,
  content,
  additionalContent = null,
  color = 'primary',
  sx,
  variant = 'regular',
  ...other
}) {
  const padding = (variant) => {
    switch (variant) {
      case 'compact':
        return 3;
      default:
        return 5;
    }
  };

  const contentSize = (variant) => {
    if (additionalContent !== null) {
      switch (variant) {
        case 'compact':
          return 'h5';
        default:
          return 'h4';
      }
    } else {
      switch (variant) {
        case 'compact':
          return 'h4';
        default:
          return 'h3';
      }
    }
  };

  return (
    <Card
      sx={{
        py: padding(variant),
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      {variant === 'compact' && (
        <StyledIconCompact
          sx={{
            color: (theme) => theme.palette[color].dark,
          }}
        >
          <Typography variant={contentSize(variant)}>{content}</Typography>
        </StyledIconCompact>
      )}
      {variant !== 'compact' && (
        <StyledIcon
          sx={{
            color: (theme) => theme.palette[color].dark,
          }}
        >
          <Typography variant={contentSize(variant)}>{content}</Typography>
          {additionalContent !== null && (
            <Typography variant={contentSize(variant)} sx={{ marginLeft: 1 }}>
              {additionalContent}
            </Typography>
          )}
        </StyledIcon>
      )}

      <Typography variant="h6" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
    </Card>
  );
}
