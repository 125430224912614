import { Container, Skeleton } from '@mui/material';

const LoadingState = () => (
  <Container>
    <Skeleton variant="text" sx={{ width: '30%', fontSize: '3rem' }} />
    <Skeleton variant="text" sx={{ fontSize: '1rem', mt: 3}} />
    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

    <Skeleton variant="text" sx={{ fontSize: '1rem', mt: 3 }} />
    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

    <Skeleton variant="text" sx={{ fontSize: '1rem', mt: 3 }} />
    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
  </Container>
);

export default LoadingState;