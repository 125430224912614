import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import { styled } from '@mui/material/styles';
import { Card, Grid } from '@mui/material';
import SettingsDetailContent from './SettingsDetailContent';
import SettingsPlanContent from './SettingsPlanContent';

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '90vh',
  width: '80vw',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4, 0),
}));

export default function SettingsPage() {
  const [label] = React.useState('Plano e pagamento');
  const [content] = React.useState('plan');

  return (
    <>
      <Helmet>
        <title>CélulaHub</title>
      </Helmet>

      <StyledContent>
        <Card sx={{ p: 4 }}>
          <Grid container spacing={2}>
            <Grid item md={9}>
              {content === 'details' && <SettingsDetailContent />}
              {content === 'plan' && <SettingsPlanContent />}
            </Grid>
          </Grid>
        </Card>
      </StyledContent>
    </>
  );
}