import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';
import { useLocalStorage } from '@uidotdev/usehooks';
import useNetwork from '../../hooks/useNetwork';
import SubscriptionContent from './SubscriptionContent';
import SubscribedContent from './SubscribedContent';
import LoadingState from '../../common/LoadingState';

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '90vh',
  width: '90vw',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4, 0),
}));

const SubscriptionPage = ({ setupButton }) => {
  const [loading, setLoading] = React.useState(true);
  const [subscription, setSubscription] = React.useState(null);
  const [organizationId] = useLocalStorage('organizationId', '');
  const { request } = useNetwork();

  // TODO: refresh when signing up for plan
  const checkSubscriptionStatus = React.useCallback(async () => {
    try {
      const response = await request(`v1/subscriptions/${organizationId}/active`, 'GET');
      if (response.data.length === 0) {
        setSubscription(null);
      } else {
        setSubscription(response.data[0]); 
      }
      
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [organizationId, request]);

  React.useEffect(() => {
    setupButton({});
    checkSubscriptionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>CélulaHub</title>
      </Helmet>

      <StyledContent>
        <Card sx={{ p: 4 }}>
          {loading && <LoadingState />}
          {!loading && !subscription && <SubscriptionContent />}
          {!loading && subscription && <SubscribedContent subscription={subscription} onCancel={() => checkSubscriptionStatus()} />}
        </Card>
      </StyledContent>
    </>
  );
}

SubscriptionPage.propTypes = {
  setupButton: PropTypes.func
};

export default SubscriptionPage;