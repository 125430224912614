import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const StyledContent = styled('div')(({ theme }) => ({
  minWidth: 300,
  [theme.breakpoints.up('md')]: {
    minWidth: 450,
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: 600,
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: 750,
  },
  justifyContent: 'center',
  textAlign: 'center',
  padding: theme.spacing(24, 0),
}));

EmptyState.propTypes = {
  title: PropTypes.string,
};

export default function EmptyState(props) {
  return (
    <>
      <StyledContent>
        <Typography variant="h5" sx={{ opacity: 0.6 }}>{props.title}</Typography>
      </StyledContent>
    </>
  );
}
