import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';

const StyledContent = styled('div')(() => ({
  width: '100%',
  margin: 'auto',
  justifyContent: 'center',
  alignSelf: 'center',
}));

/**
 * Dropdown menu component
 * 
 * @param {array} items - The options of the menu
 * @param {object} defaultValue - The default value of the dropdown, this needs to be an object and not merely a string
 * @param {function} onChange - The function to be called when the value of the dropdown changes
 * @param {boolean} readOnly - Whether the dropdown vale can be changed or not
 */
export const DropdownMenu = ({ items, defaultValue, onChange, readOnly }) => {
  const [option, setOption] = useState(defaultValue);

  useEffect(() => {
    setOption(defaultValue);
  }, [defaultValue]);

  const handleChange = (event) => {
    const selected = items.find((e) => e.label === event.target.value);
    setOption(selected);
    onChange(selected.value);
  };

  return (
    <>
      <StyledContent>
        <Select
          sx={{ width: '100%' }}
          labelId={`dropdown-${crypto.randomUUID()}`}
          id={crypto.randomUUID()}
          value={option !== undefined ? option.label : ''}
          onChange={handleChange}
          readOnly={readOnly}
        >
          {items.map((option) => (
            <MenuItem key={option.value} value={option.label} sx={{ typography: 'body2' }}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </StyledContent>
    </>
  );
}

DropdownMenu.propTypes = {
  items: PropTypes.array,
  defaultValue: PropTypes.object,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};
