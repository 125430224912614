import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Card, TableCell } from '@mui/material';
import PaginatedTable from '../components/table/PaginatedTable';
import useNetwork from '../hooks/useNetwork';
import EmptyState from '../sections/reports/overview/EmptyState';
import PersonDetailModal from './PersonDetailModal';

const StyledContent = styled('div')(({ theme }) => ({
  minWidth: 300,
  [theme.breakpoints.up('md')]: {
    minWidth: 450,
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: 600,
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: 750,
  },
  justifyContent: 'start',
  textAlign: 'start',
}));
OrganizationMembersTab.propTypes = {
  id: PropTypes.string
}

export default function OrganizationMembersTab(props) {
  const [shouldUpdate, setShouldUpdate] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [person, setPerson] = React.useState({});
  const [rows, setRows] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const { request } = useNetwork();

  const handleClick = (row) => {
    setOpen(true);
    setPerson(row);
  };

  const onFetch = React.useCallback(async (page) => {
    try {
      const response = await request(`v1/organizations/${props.id}/people?page=${page}`, 'GET');
      const { people, count } = response.data;
      setRows(page === 0 ? people : rows.concat(people));
      setTotalCount(count);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const onClose = (updated) => {
    setShouldUpdate(updated);
    setOpen(false);
  };

  React.useEffect(() => {
    if (shouldUpdate) {
      onFetch(0);
      setShouldUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdate, rows]);

  return (
    <>
      <StyledContent>
        <Card sx={{ mt: 4 }}>
          {rows.length === 0 && <EmptyState title='Nenhum participante' />}
          {rows.length > 0 && (
            <PaginatedTable
              rows={rows}
              totalCount={totalCount}
              onClickRow={handleClick}
              onPaginate={(page) => onFetch(page)}
              head={
                <>
                  <TableCell>Nome</TableCell>
                  <TableCell>Telefone</TableCell>
                  <TableCell>Email</TableCell>
                </>
              }
              body={(row, labelId) =>
                <>
                  <TableCell component="th" id={labelId} scope="row">
                    {`${row.firstName} ${row.lastName}`}
                  </TableCell>
                  <TableCell id={labelId} scope="row">
                    {row.phone}
                  </TableCell>
                  <TableCell id={labelId} scope="row">
                    {row.email}
                  </TableCell>
                </>
              } />
          )}
        </Card>
      </StyledContent>
      <PersonDetailModal mode='edit' person={person} open={open} onClose={(updated) => onClose(updated)} />
    </>
  );
}