import * as React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import SimpleLayout from './layouts/simple';
import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
import GroupOverviewPage from './pages/GroupOverviewPage';
import NoAccessPage from './pages/NoAccessPage';
import OverviewPage from './pages/OverviewPage';
import SettingsPage from './pages/settings/SettingsPage';
import SubscriptionPage from './pages/settings/SubscriptionPage';
import SubscriptionSuccessPage from './pages/settings/SubscriptionSuccessPage';

export default function Router() {
  const [button, setButton] = React.useState({});

  const setupButton = (button) => {
    setButton(button);
  }

  const triggerUpdate = () => {
    // TODO: this makes the screen go blank, not a good experience
    window.location.reload();
  }

  return useRoutes([
    {
      path: '/',
      element: <SimpleLayout button={button} shouldUpdate={triggerUpdate} />,
      children: [
        { element: <Navigate to='/overview' />, index: true },
        { path: 'overview', element: <OverviewPage setupButton={setupButton} /> },
        { path: 'group-overview', element: <GroupOverviewPage setupButton={setupButton} /> },
        { path: 'settings', element: <SettingsPage /> },
        { path: 'subscription', element: <SubscriptionPage setupButton={setupButton} /> },
        { path: 'subscription/create-success', element: <SubscriptionSuccessPage />}
      ],
    },
    {
      path: 'no-access',
      element: <NoAccessPage />,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '404',
      element: <NotFoundPage />
    },
    {
      path: '*',
      element: <Navigate to='/404' replace />,
    },
  ]);
}
