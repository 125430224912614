import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, TableCell, } from '@mui/material';
import PaginatedTable from '../components/table/PaginatedTable';
import useNetwork from '../hooks/useNetwork';
import EmptyState from '../sections/reports/overview/EmptyState';
import { weekdayToString, groupTypeToString } from '../utils/GroupUtil';

OrganizationGroupListTab.propTypes = {
  id: PropTypes.string
}

export default function OrganizationGroupListTab(props) {
  const [rows, setRows] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const navigate = useNavigate();
  const { request } = useNetwork();

  const handleClick = (row) => {
    navigate('/overview', { state: { row, role: 'LEADER' } });
  };

  const onFetch = React.useCallback(async (page) => {
    try {
      const response = await request(`v1/organizations/${props.id}/groups?page=${page}`, 'GET');
      const { groups, count } = response.data;
      setRows(page === 0 ? groups : rows.concat(groups));
      setTotalCount(count);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  React.useEffect(() => {
    onFetch(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card sx={{ mt: 3 }}>
        {rows.length === 0 && <EmptyState title='Nenhuma supervisão' />}
        {rows.length > 0 && (
          <PaginatedTable
            rows={rows}
            totalCount={totalCount}
            onClickRow={handleClick}
            onPaginate={(page) => onFetch(page)}
            head={
              <>
                <TableCell>Nome</TableCell>
                <TableCell>Líder</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Dia da semana</TableCell>
                <TableCell>Endereço</TableCell>
              </>
            }
            body={(row, labelId) =>
              <>
                <TableCell component="th" id={labelId} scope="row">
                  {row.name}
                </TableCell>
                <TableCell id={labelId} scope="row">
                  {row.leader !== null && row.leader !== undefined ? `${row.leader.firstName} ${row.leader.lastName}` : 'Sem líder'}
                </TableCell>
                <TableCell id={labelId} scope="row">
                  {groupTypeToString(row.type)}
                </TableCell>
                <TableCell id={labelId} scope="row">
                  {weekdayToString(row.weekday)}
                </TableCell>
                <TableCell id={labelId} scope="row">
                  {row.address}
                </TableCell>
              </>
            }
          />
        )}
      </Card>
    </>
  );
}