import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import Logo from '../components/logo';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: '80vw',
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function NotFoundPage() {
  return (
    <>
      <Helmet>
        <title>CélulaHub</title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />
      </StyledRoot>

      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Box
            component="img"
            src="/assets/illustrations/illustration_404.svg"
            sx={{ height: 150, mx: 'auto' }}
          />

          <Container sx={{ my: { xs: 4, sm: 8 } }}>
            <Typography variant="h3" paragraph>
              Página não encontrada
            </Typography>

            <Typography sx={{ color: 'text.secondary' }}>
              Desculpe, não conseguimos encontrar a página que você está procurando. Talvez você tenha digitado a URL incorretamente? Certifique-se de verificar a ortografia.
            </Typography>
          </Container>

          <Button to="/" size="large" variant="contained" component={RouterLink}>
            Ir para Home
          </Button>
        </StyledContent >
      </Container >
    </>
  );
}
