import PropTypes from 'prop-types';
import { Button, Typography, Stack } from '@mui/material';

export const GroupInfo = ({ name, onClick }) => (
  <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex" sx={{ mb: 2 }}>
    <Typography variant="h4" sx={{ flex: 1 }}>
      {name}
    </Typography>
    <Button onClick={onClick} sx={{ flex: 0 }}>
      Editar
    </Button>
  </Stack>
);

GroupInfo.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
};