import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { KindeProvider } from "@kinde-oss/kinde-auth-react";
import Router from './routes';
import ThemeProvider from './theme';
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <KindeProvider
          clientId={process.env.REACT_APP_KINDE_CLIENT_ID}
          domain={process.env.REACT_APP_KINDE_DOMAIN}
          redirectUri={process.env.REACT_APP_KINDE_REDIRECT_URI}
          logoutUri={process.env.REACT_APP_KINDE_LOGOUT_URI}
          isDangerouslyUseLocalStorage={process.env.REACT_APP_ENVIRONMENT === 'staging'}>
          <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <Router />
          </ThemeProvider>
        </KindeProvider>
      </BrowserRouter>
    </HelmetProvider >
  );
}
