import useNetwork from "../useNetwork";

export const useGroupClient = () => {
  const { request } = useNetwork();
  
  const createGroup = async (id, body, fromSupergroup) => {
    body.id = crypto.randomUUID();
    try {
      if (fromSupergroup) {
        const { data } = await request(`v1/groups/${id}/children`, 'POST', body);
        return { data, error: undefined };
      }

      const { data } = await request(`v1/organizations/${id}/groups`, 'POST', body);
      return { data, error: undefined };
    } catch (error) {
      console.log(error);
      return { data: undefined, error };
    }
  };

  const updateGroup = async (id, body) => {
    try {
      const { data } = await request(`v1/groups/${id}`, 'PUT', body);
      return { data, error: undefined };
    } catch (error) {
      console.log(error);
      return { data: undefined, error };
    }
  };

  return { createGroup, updateGroup };
}