import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Divider, TextField, Typography } from "@mui/material";

const StyledContent = styled('div')(({ theme }) => ({
  minWidth: 300,
  [theme.breakpoints.up('md')]: {
    minWidth: 450,
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: 600,
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: 750,
  },
  margin: 'auto',
  minHeight: '70vh',
  display: 'flex',
  flexDirection: 'column',
}));

export default function SettingsDetailContent() {
  const [name, setName] = React.useState('');

  const handleChange = (input, value) => {
    switch (input) {
      case 'name':
        setName(value);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Container>
        <StyledContent>
          <Typography variant="h3" paragraph>Detalhes</Typography>
          <Typography variant="body1" paragraph>Gerencie os detalhes de sua organização aqui</Typography>

          <TextField
              id="filled-basic"
              fullWidth
              label="Nome da organização"
              variant="outlined"
              value={name}
              sx={{ mt: 3}}
              onChange={(newValue) => handleChange('name', newValue.target.value)}
            />

            <Divider sx={{mt: 5}} />
        </StyledContent>
      </Container>
    </>
  );
}