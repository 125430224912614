import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';

PaginatedTable.propTypes = {
  totalCount: PropTypes.number,
  onPaginate: PropTypes.func,
  onClickRow: PropTypes.func,
  provideBaseRow: PropTypes.bool,
  head: PropTypes.object,
  body: PropTypes.func,
  rows: PropTypes.array,
}

PaginatedTable.defaultProps = {
  provideBaseRow: true
}

export default function PaginatedTable(props) {
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 20;

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const visibleRows = React.useMemo(
    () =>
      rows.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [rows, page, rowsPerPage],
  );

  const handleChangePage = (event, newPage) => {
    props.onPaginate(newPage);
    setPage(newPage);
  };

  React.useEffect(() => {
    setRows(props.rows)
  }, [props.rows]);

  return (
    <>
      <Box>
        <TableContainer component={Paper}>
          <Table aria-label="table">
            <TableHead>
              <TableRow key={`${crypto.randomUUID()}`}>
                {props.head}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.provideBaseRow && visibleRows.map((row, index) =>
              (
                <TableRow
                  hover
                  onClick={() => props.onClickRow(row)}
                  tabIndex={-1}
                  key={`${row.id}-${index}`}
                  sx={{ cursor: 'pointer' }}
                >
                  {props.body(row, `row-${index}`)}
                </TableRow>
              )
              )}
              {!props.provideBaseRow && visibleRows.map((row, index) =>
              (
                <>
                  {props.body(row, `row-${index}`)}
                </>
              )
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component='div'
          count={props.totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Box>
    </>
  );
}