import * as React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import CustomModal from '../components/CustomModal';
import useAuth from '../hooks/useAuthentication';
import { useMe } from '../hooks/useMe';
import useNetwork from '../hooks/useNetwork';

const PERSON_STATE = {
  id: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  role: '',
  groupId: '',
};

PersonDetailModal.propTypes = {
  mode: PropTypes.string,
  person: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  groupId: PropTypes.string,
}

export default function PersonDetailModal(props) {
  const [person, setPerson] = React.useState(PERSON_STATE);
  const [firstName, setFirstName] = React.useState('');
  const [firstNameError, setFirstNameError] = React.useState(false);
  const [lastName, setLastName] = React.useState('');
  const [lastNameError, setLastNameError] = React.useState(false);
  const [phone, setPhone] = React.useState('');
  const [phoneError, setPhoneError] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);
  const [birthday, setBirthday] = React.useState('');

  const { request } = useNetwork();
  const { isLoading } = useAuth();
  const { getMe } = useMe();

  const handleChange = (input, value) => {
    switch (input) {
      case 'firstName':
        setFirstName(value);
        setFirstNameError(false);
        break;
      case 'lastName':
        setLastName(value);
        setLastNameError(false);
        break;
      case 'phone':
        setPhone(value);
        setPhoneError(false);
        break;
      case 'email':
        setEmail(value);
        setEmailError(false);
        break;
      case 'birthday':
        setBirthday(value);
        break;
      default:
        break;
    }

    person[input] = value;
  };

  const fillPerson = React.useCallback(async (propsPerson) => {
    setFirstName(propsPerson.firstName);
    setLastName(propsPerson.lastName);
    setPhone(propsPerson.phone);
    setEmail(propsPerson.email);
    setBirthday(propsPerson.birthday);

    person.id = propsPerson.id;
    person.role = propsPerson.role;
    person.groupId = propsPerson.groupId;
    person.firstName = propsPerson.firstName;
    person.lastName = propsPerson.lastName;
    person.phone = propsPerson.phone;
    person.email = propsPerson.email;
    person.birthday = propsPerson.birthday;
  }, [person]);

  const onFetch = React.useCallback(async () => {
    const { data } = await getMe(props.person);
    if (data !== undefined) {
      fillPerson(data);
    }
  }, [fillPerson, props, getMe]);

  React.useEffect(() => {
    if (!props.open) {
      return;
    }

    switch (props.mode) {
      case 'fetch':
        onFetch();
        break;
      case 'edit':
        fillPerson(props.person);
        break;
      default:
        setPerson(PERSON_STATE);
        person.groupId = props.person.groupId;
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const isFormValid = () => {
    let isValid = true;
    
    if (person.firstName === null || person.firstName.length === 0) {
      setFirstNameError(true);
      isValid = false;
    }
    
    if (person.lastName === null || person.lastName.length === 0) {
      setLastNameError(true);
      isValid = false;
    }
    
    if (person.phone === null || person.phone.length === 0) {
      setPhoneError(true);
      isValid = false;
    }
    
    if (person.email === null || person.email.length === 0) {
      setEmailError(true);
      isValid = false;
    }
    
    return isValid;
  };

  const onComplete = React.useCallback(async () => {
    if (!isFormValid()) {
      return;
    }
    
    const shouldCreate = props.mode === 'create';

    try {
      if (shouldCreate) {
        person.id = crypto.randomUUID();
        person.role = 'MEMBER';

        await request(`v1/groups/${person.groupId}/people`, 'POST', person);
      } else {
        await request(`v1/people/${person.id}`, 'PUT', person);
      }

      setPerson(PERSON_STATE);
      props.onClose(true, { model: 'person', type: shouldCreate ? 'create' : 'update' });
    } catch (error) {
      console.log(`error: ${JSON.stringify(error)}`);
      props.onClose(false, { model: 'person', type: shouldCreate ? 'create' : 'update' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstNameError, lastNameError, phoneError, emailError, isLoading]);

  const getHeading = () => {
    switch (props.mode) {
      case 'fetch':
        return 'Editar minhas informações';
      case 'edit':
        return 'Editar participante';
      case 'create':
        return 'Criar participante';
      default:
        return '';
    }
  }

  return (
    <>
      <CustomModal
        title={getHeading()}
        action='Salvar'
        open={props.open}
        onClose={props.onClose}
        onAction={onComplete}
      >
        <TextField
          required
          error={firstNameError}
          id="filled-basic"
          fullWidth
          label="Nome"
          variant="outlined"
          value={firstName}
          onChange={(newValue) => handleChange('firstName', newValue.target.value)}
        />
        <TextField
          required
          error={lastNameError}
          id="filled-basic"
          fullWidth
          label="Sobrenome"
          value={lastName}
          variant="outlined"
          onChange={(newValue) => handleChange('lastName', newValue.target.value)}
        />
        <TextField
          required
          error={phoneError}
          id="filled-basic"
          label="Telefone"
          value={phone}
          variant="outlined"
          onChange={(newValue) => handleChange('phone', newValue.target.value)}
        />
        <TextField
          required
          error={emailError}
          id="filled-basic"
          label="Email"
          value={email}
          variant="outlined"
          disabled={props.mode === 'fetch'}
          onChange={(newValue) => handleChange('email', newValue.target.value)}
        />
        {false && <TextField
          id="filled-basic"
          multiline
          label="Aniversário"
          value={birthday}
          variant="outlined"
          onChange={(newValue) => handleChange('birthday', newValue.target.value)}
        />}
      </CustomModal>
    </>
  );
}
