import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const CustomDialog = ({ title, content, open, positiveAction, negativeAction, onAction, onClose }) =>
(
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {content}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {negativeAction && <Button onClick={() => onAction(false)}>{negativeAction}</Button>}
      <Button onClick={() => onAction(true)} autoFocus>
        {positiveAction}
      </Button>
    </DialogActions>
  </Dialog>
);

CustomDialog.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  open: PropTypes.bool,
  positiveAction: PropTypes.string,
  negativeAction: PropTypes.string,
  onAction: PropTypes.func,
  onClose: PropTypes.func,
};

export default CustomDialog;