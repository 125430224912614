import { Helmet } from "react-helmet-async";
import { styled } from '@mui/material/styles';
import { Button, Container, Typography } from "@mui/material";
import Logo from '../components/logo';
import useAuth from '../hooks/useAuthentication';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: '75vw',
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function NoAccessPage() {
  const { logout } = useAuth();

  return (
    <>
      <Helmet>
        <title>CélulaHub</title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />
      </StyledRoot>

      <Container>
        <StyledContent sx={{ textAlign: 'start', alignItems: 'start' }}>
          <Typography variant="h3" paragraph>
            Sem acesso
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Atualmente, você não possui acesso à plataforma. Para iniciar, há duas opções disponíveis:
          </Typography>

          <Typography variant='h6' sx={{ mt: 4 }}>
            Solicite ao administrador da sua organização
          </Typography>

          <Typography sx={{ color: 'text.secondary', mt: 1 }}>
            Se você faz parte de uma organização que já utiliza a plataforma, entre em contato com o administrador para solicitar acesso. Eles serão capazes de adicionar sua conta e fornecer as permissões necessárias.
          </Typography>

          {/* Add contact information and redirect to plans page. */}
          <Typography variant='h6' sx={{ mt: 3 }}>
            Crie uma nova organização
          </Typography>
          <Typography sx={{ color: 'text.secondary', mt: 1 }}>
            Caso sua organização ainda não esteja registrada, você pode criar uma nova organização. Isso permitirá que você inicie sua própria instância da plataforma e convide membros para participarem.
          </Typography>
          <Typography sx={{ mt: 4 }}>
            Estamos aqui para ajudar! Se precisar de mais assistência ou informações, não hesite em entrar em contato conosco.
          </Typography>

          <Button size="large" variant="contained" onClick={logout} sx={{ minWidth: '150px', display: 'flex', alignSelf: 'center', mt: 7 }}>
            Sair
          </Button>
        </StyledContent>
      </Container>
    </>
  );
}