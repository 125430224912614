import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocalStorage } from '@uidotdev/usehooks';
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
import { useMe } from '../hooks/useMe';
import { SupergroupOverviewPage } from './SupergroupOverviewPage';
import OrganizationOverviewPage from './OrganizationOverviewPage';
import GroupOverviewPage from './GroupOverviewPage';
import useAuth from '../hooks/useAuthentication';

const StyledContent = styled('div')(({ theme }) => ({
  minWidth: 300,
  [theme.breakpoints.up('md')]: {
    minWidth: 450,
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: 600,
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: 750,
  },
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4, 0),
}));

const OverviewPage = ({ setupButton }) => {
  const [groupId, setGroupId] = React.useState('');
  const [step, setStep] = React.useState('');
  const [, setRole] = useLocalStorage('role', '');
  const [, setOrganizationId] = useLocalStorage('organizationId', '');
  const [me, setMe] = useLocalStorage('me', {});

  const { getMe } = useMe();
  const { isAuthenticated, isLoading, user } = useAuth();
  const navigate = useNavigate();
  const params = useLocation();

  const fetchMe = React.useCallback(async () => {
    if (!isAuthenticated) {
      return;
    }

    const { data } = await getMe(user.email);
    if (data !== undefined) {
      setMe(data);
      setGroupId(data.group.id);
      setStep(data.role);

      // TODO: remove this and rely on me
      setOrganizationId(data.organizationId);
    } else {
      navigate('/no-access');
    }
  }, [isAuthenticated, getMe, navigate, user, setMe, setOrganizationId]);

  /**
   * There are two ways to open this page:
   * 1. Initial entry point
   *    - This is the starting point of the app, all info should be retrieved from the getMe hook and stored in the local storage
   * 2. From a list of groups in the SupergroupOverviewTab or OrganizationGroupListTab
   *    - When clicking in the group from a list will navigate here, all info should be retrieved from the params object
   */
  React.useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigate('/login');
    }

    if (isAuthenticated) {
      const fromListOfGroups = params.state !== null && params.state.row !== null;
      if (fromListOfGroups) {
        setGroupId(params.state.row.id);
        setStep(params.state.role);
        setRole(params.state.role);
      } else {
        fetchMe();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading, params]);

  return (
    <>
      <Helmet>
        <title>CélulaHub</title>
      </Helmet>

      <StyledContent>
        <Container>
          {step === 'ADMIN' && <OrganizationOverviewPage id={me.organizationId} setupButton={setupButton} />}
          {step === 'SUPERVISOR' && <SupergroupOverviewPage group={me.group} setupButton={setupButton} />}
          {step === 'LEADER' && <GroupOverviewPage id={groupId} setupButton={setupButton} />}
        </Container>
      </StyledContent>
    </>
  );
}

OverviewPage.propTypes = {
  setupButton: PropTypes.func
}

export default OverviewPage;