import * as React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { Card, TableCell } from '@mui/material';
import PaginatedTable from '../../../components/table/PaginatedTable';
import ReportDetailModal from '../../../pages/ReportDetailModal';
import useNetwork from '../../../hooks/useNetwork';

import { fDate } from '../../../utils/formatTime';
import { mapMeetingTypeToLabel } from './MeetingUtil';
import EmptyState from './EmptyState';

const StyledContent = styled('div')(({ theme }) => ({
  minWidth: 300,
  [theme.breakpoints.up('md')]: {
    minWidth: 450,
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: 600,
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: 750,
  },
  justifyContent: 'start',
  textAlign: 'start',
}));

ReportsTab.propTypes = {
  groupId: PropTypes.string
}

export default function ReportsTab(props) {
  const [open, setOpen] = React.useState(false);
  const [shouldUpdate, setShouldUpdate] = React.useState(true);
  const [report, setReport] = React.useState({});

  const [rows, setRows] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const { request } = useNetwork();

  const handleClick = (row) => {
    setOpen(true);
    setReport(row);
  };

  const onFetch = React.useCallback(async (page) => {
    try {
      const response = await request(`v1/groups/${props.groupId}/reports?page=${page}`, 'GET');
      const { reports, count } = response.data;
      setRows(page === 0 ? reports : rows.concat(reports));
      setTotalCount(count);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  React.useEffect(() => {
    if (shouldUpdate) {
      onFetch(0);
      setShouldUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdate]);

  const onClose = (updated) => {
    setShouldUpdate(updated);
    setOpen(false);
  };

  return (
    <>
      <StyledContent>
        <Card sx={{ mt: 4 }}>
          {rows.length === 0 && <EmptyState title="Nenhum relatório" />}
          {rows.length > 0 && (
            <PaginatedTable
              rows={rows}
              totalCount={totalCount}
              onClickRow={handleClick}
              onPaginate={(page) => onFetch(page)}
              head={
                <>
                  <TableCell>Data</TableCell>
                  <TableCell>Modalidade</TableCell>
                  <TableCell>Lição</TableCell>
                  <TableCell>Ministrante</TableCell>
                  <TableCell>Presentes</TableCell>
                </>
              }
              body={(row, labelId) =>
                <>
                  <TableCell component="th" id={labelId} scope="row">
                    {fDate(row.date)}
                  </TableCell>
                  <TableCell id={labelId} scope="row">
                    {mapMeetingTypeToLabel(row.type)}
                  </TableCell>
                  <TableCell id={labelId} scope="row">
                    {!row.lesson || row.lesson === '' ? '-' : row.lesson}
                  </TableCell>
                  <TableCell id={labelId} scope="row">
                    {!row.teacher || row.teacher === '' ? '-' : row.teacher}
                  </TableCell>
                  <TableCell id={labelId} scope="row">
                    {row.attendance === undefined || row.attendance === null || row.attendance === ''
                      ? 0
                      : row.attendance.split(',').length}
                  </TableCell>
                </>
              }
            />
          )}
        </Card>
      </StyledContent>

      <ReportDetailModal mode='edit' report={report} groupId={props.groupId} open={open} onClose={(updated) => onClose(updated)} />
    </>
  );
}
