import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Grid, Stack, Typography } from '@mui/material';
import useNetwork from '../hooks/useNetwork';
import SummaryBox from '../sections/reports/overview/SummaryBox';
import GroupFrequency from '../sections/reports/overview/GroupFrequency';
import OrganizationDetailModal from './OrganizationDetailModal';
import PieChart from '../components/PieChart';

OrganizationOverviewTab.propTypes = {
  id: PropTypes.string
}

export default function OrganizationOverviewTab(props) {
  const [open, setOpen] = React.useState(false);
  const [shouldUpdate, setShouldUpdate] = React.useState(true);
  const [data, setData] = React.useState({});
  const { request } = useNetwork();

  React.useEffect(() => {
    if (shouldUpdate) {
      request(`v1/organizations/${props.id}/overview`, 'GET').then((response) => {
        setData(response.data);
      }).catch((error) => {
        console.log(error);
      });

      setShouldUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdate]);

  const onClose = (updated) => {
    setShouldUpdate(updated);
    setOpen(false);
  };

  const peopleEvolutionData = {
    name: 'Participantes',
    type: 'line',
    fill: 'solid',
    data: data.peopleEvolution !== undefined ? data.peopleEvolution.map((e) => e.value) : [0],
  };

  const groupEvolutionData = {
    name: 'Células',
    type: 'line',
    fill: 'solid',
    data: data.groupEvolution !== undefined ? data.groupEvolution.map((e) => e.value) : [0],
  };

  return (
    <>
      <Container sx={{ mt: 2 }}>
        {/* TODO: replace this with GroupInfo component */}
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex" sx={{ mb: 3 }}>
          <Typography variant="h4" sx={{ flex: 1 }}>
            {data.name !== undefined ? data.name : ''}
          </Typography>
          <Button onClick={() => setOpen(true)} sx={{ flex: 0 }}>
            Editar
          </Button>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <SummaryBox title="Número de pessoas" content={data.peopleCount} />
          </Grid>
          <Grid item xs={12} md={4}>
            <SummaryBox title="Número de células" content={data.groupCount} />
          </Grid>
          <Grid item xs={12} md={4}>
            <SummaryBox title="Número de relatórios" content={data.reportCount} />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} sx={{ mt: 3 }}>
            <PieChart
              title='Presencial ou online'
              chart={{
                series: [
                  { label: 'Não houve', value: data.noMeetings || 0 },
                  { label: 'Online', value: data.onlineMeetings || 0 },
                  { label: 'Presencial', value: data.inPersonMeetings || 0 },
                ]
              }} />
          </Grid>

          <Grid item xs={12} md={6} sx={{ mt: 3 }}>
            <PieChart
              title='Tipos de célula'
              chart={{
                series: [
                  { label: 'Mista', value: data.mixedGroups || 0 },
                  { label: 'Masculina', value: data.maleGroups || 0 },
                  { label: 'Feminina', value: data.femaleGroups || 0 },
                  { label: 'Infantil', value: data.kidsGroups || 0 },
                ]
              }} />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} sx={{ mt: 3 }}>
            <GroupFrequency
              title='Número de células'
              chartLabels={data.groupEvolution !== undefined ? data.groupEvolution.map((e) => e.date) : []}
              chartData={[groupEvolutionData]}
            />
          </Grid>

          <Grid item xs={12} md={6} sx={{ mt: 3 }}>
            <GroupFrequency
              title='Participantes em células'
              chartLabels={data.peopleEvolution !== undefined ? data.peopleEvolution.map((e) => e.date) : []}
              chartData={[peopleEvolutionData]}
            />
          </Grid>
        </Grid>
      </Container>
      <OrganizationDetailModal organizationId={props.id} open={open} onClose={(updated) => onClose(updated)} />
    </>
  );
}