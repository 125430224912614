import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';

const StyledContent = styled('div')(({ theme }) => ({
  minWidth: 300,
  [theme.breakpoints.up('md')]: {
    minWidth: 450,
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: 600,
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: 750,
  },
  justifyContent: 'start',
  textAlign: 'start',
}));

InfoStep.propTypes = {
  report: PropTypes.object,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
};

const INFO_STATE = {
  lesson: '',
  teacher: '',
  comment: '',
  visit: false,
  mood: 'NEUTRAL',
};

export default function InfoStep(props) {
  const [infoState] = React.useState(INFO_STATE);
  const [lesson, setLesson] = React.useState(infoState.lesson);
  const [teacher, setTeacher] = React.useState(infoState.teacher);
  const [comment, setComment] = React.useState(infoState.comment);
  const [visit, setVisit] = React.useState(infoState.visit);
  const [mood, setMood] = React.useState(infoState.mood);

  React.useEffect(() => {
    if (props.report !== null) {
      setLesson(props.report.lesson);
      setTeacher(props.report.teacher);
      setComment(props.report.comment);
      setVisit(props.report.visit);

      const { mood } = props.report;
      setMood(mood === '' ? 'NEUTRAL' : mood);
    }
  }, [props]);

  const handleChange = (input, value) => {
    switch (input) {
      case 'lesson':
        setLesson(value);
        break;
      case 'teacher':
        setTeacher(value);
        break;
      case 'comment':
        setComment(value);
        break;
      case 'visit':
        setVisit(value);
        break;
      case 'mood':
        setMood(value);
        break;
      default:
        break;
    }

    infoState[input] = value;
    props.onChange(input, value);
  };

  return (
    <>
      <StyledContent>
        <Stack direction="column" spacing={2}>
          <TextField
            id="filled-basic"
            fullWidth
            label="Lição"
            variant="outlined"
            value={lesson}
            onChange={(newValue) => handleChange('lesson', newValue.target.value)}
            InputProps={{
              readOnly: props.readOnly,
            }}
          />
          <TextField
            id="filled-basic"
            fullWidth
            label="Ministrante"
            value={teacher}
            variant="outlined"
            onChange={(newValue) => handleChange('teacher', newValue.target.value)}
            InputProps={{
              readOnly: props.readOnly,
            }}
          />
          <TextField
            id="filled-basic"
            multiline
            rows={3}
            label="Comentário"
            value={comment}
            variant="outlined"
            onChange={(newValue) => handleChange('comment', newValue.target.value)}
            InputProps={{
              readOnly: props.readOnly,
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={visit}
                onChange={(newValue) => {
                  if (props.readOnly) {
                    return;
                  }
                
                  handleChange('visit', newValue.target.checked)
                }}
                />
            }
            label="Visita da supervisão"
          />

          <FormControl>
            <FormLabel id="mood-radio">Presença de Deus</FormLabel>
            <RadioGroup aria-labelledby="mood-radio" row defaultValue="neutral" name="radio-buttons-group">
              <FormControlLabel
                value="sad"
                control={
                  <Radio
                    size="small"
                    checked={mood === 'SAD'}
                    onChange={() => {
                      if (props.readOnly) {
                        return;
                      }

                      handleChange('mood', 'SAD')
                    }}
                  />
                }
                label="🙁"
              />
              <FormControlLabel
                value="neutral"
                control={
                  <Radio
                    size="small"
                    checked={mood === 'NEUTRAL'}
                    onChange={() => {
                      if (props.readOnly) {
                        return;
                      }

                      handleChange('mood', 'NEUTRAL')
                    }}
                  />
                }
                label="😐"
              />
              <FormControlLabel
                value="happy"
                control={
                  <Radio
                    size="small"
                    checked={mood === 'HAPPY'}
                    onChange={() => {
                      if (props.readOnly) {
                        return;
                      }
                      
                      handleChange('mood', 'HAPPY')
                    }}
                  />
                }
                label="😊"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      </StyledContent>
    </>
  );
}
