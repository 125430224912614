export function groupTypeToString(type) {
  switch (type) {
    case 'DEACTIVATED':
      return 'Inativa';
    case 'FEMALE':
      return 'Feminino';
    case 'KIDS':
      return 'Infantil';
    case 'MALE':
      return 'Masculino';
    case 'MIXED':
      return 'Misto';
    case 'SUPERGROUP':
      return 'Supervisão';
    default:
      return type;
  }
}

export function weekdayToString(weekday) {
  switch (weekday) {
    case 'SUNDAY':
      return 'Domingo';
    case 'MONDAY':
      return 'Segunda';
    case 'TUESDAY':
      return 'Terça';
    case 'WEDNESDAY':
      return 'Quarta';
    case 'THURSDAY':
      return 'Quinta';
    case 'FRIDAY':
      return 'Sexta';
    case 'SATURDAY':
      return 'Sábado';
    default:
      return '';
  }
}
