import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocalStorage } from '@uidotdev/usehooks';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { Container, Tab, Tabs, Typography } from '@mui/material';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import OverviewTab from '../sections/reports/overview/OverviewTab';
import ReportsTab from '../sections/reports/overview/ReportsTab';
import MembersTab from '../sections/reports/overview/MembersTab';
import { formatSnackbarMessage } from '../utils/snackbar';

const StyledContent = styled('div')(({ theme }) => ({
  minWidth: 300,
  [theme.breakpoints.up('md')]: {
    minWidth: 450,
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: 600,
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: 750,
  },
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
}));

const GroupOverviewPage = ({ id, setupButton }) => {
  const [tab, setTab] = React.useState(0);
  const [groupId, setGroupId] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [me] = useLocalStorage('me', {});

  const navigate = useNavigate();
  const params = useLocation();

  const showSnackbar = () => {
    const { model, mode, success, type } = params.state;
    const message = formatSnackbarMessage(model, mode, type, success);
    
    if (message !== undefined) {
      enqueueSnackbar(message, {
        variant: params.state.success === true ? 'success' : 'error',
        preventDuplicate: true,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
  };

  /**
   * There are two ways to open this page:
   * 1. Initial entry point
   *    - Only need to use the stored info and check if user has access to the application, the info was retrieved by OverviewPage
   * 2. From a list of groups in the SupergroupOverviewTab or OrganizationGroupListTab
   *    - Info needed is in the params object
   */
  React.useEffect(() => {
    let localId;
    if (id !== null) {
      localId = id;
      setGroupId(id);
      setLoading(false);
    } else if (params.state !== null) {
      const fromGroupList = params.state.row !== null && params.state.row !== undefined;
      const fromInitialEntryPoint = me.hasAccess;
      if (fromGroupList) {
        localId = params.state.row.id;
        setGroupId(localId);
      } else if (fromInitialEntryPoint) {
        setGroupId(me.group.id);
      } else {
        navigate('/no-access');
      }

      setLoading(groupId !== null);

      if (params.state.showSnackbar) {
        showSnackbar();
      }
    }

    setupButton({ action: { mode: 'create', key: tab === 2 ? 'person' : 'report', row: { groupId: localId } }, label: tab === 2 ? 'Participante' : 'Relatório' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  const handleChange = (_, value) => {
    setTab(value);
  };

  return (
    <>
      <Helmet>
        <title>CélulaHub</title>
      </Helmet>

      <Container>
        <SnackbarProvider />

        <StyledContent sx={{ textAlign: 'center', alignItems: 'top' }}>
          <Tabs value={tab} onChange={handleChange}>
            <Tab label="Visão geral" {...a11yProps(0)} />
            <Tab label="Relatórios" {...a11yProps(1)} />
            <Tab label="Participantes" {...a11yProps(2)} />
          </Tabs>

          {loading && <Typography>Loading</Typography>}
          {!loading && tab === 0 && <OverviewTab key={groupId} groupId={groupId} />}
          {!loading && tab === 1 && <ReportsTab key={groupId} groupId={groupId} />}
          {!loading && tab === 2 && <MembersTab key={groupId} groupId={groupId} />}
        </StyledContent>
      </Container>
    </>
  );
}

GroupOverviewPage.propTypes = {
  id: PropTypes.string,
  setupButton: PropTypes.func
}

export default GroupOverviewPage;