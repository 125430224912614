import * as React from 'react';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Container, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useNetwork from '../../hooks/useNetwork';
import SubscriptionTier from './SubscriptionTier';

const SubscriptionContent = () => {
  const [plan, setPlan] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [organizationId] = useLocalStorage('organizationId', '');
  const { request } = useNetwork();

  const createSubscription = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await request('v1/subscriptions', 'POST', { organizationId });
      window.open(response.data.redirectUrl, '_self');
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [organizationId, request]);

  const getSubscriptions = React.useCallback(async () => {
    try {
      const { data } = await request(`v1/subscriptions`, 'GET');
      setPlan(data[0]);
    } catch (error) {
      console.log(error);
    }
  }, [request]);

  React.useEffect(() => {
    getSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography variant="h3" paragraph>Assinatura mensal</Typography>
      <Typography variant="body1" paragraph sx={{ mb: 5 }}>
        Nossa assinatura mensal do aplicativo oferece um preço simples e transparente, calculado com base no número de células que você utiliza. Isso permite que você pague apenas pelo que realmente precisa, sem surpresas ou custos ocultos. O preço é atualizado automaticamente quando o número máximo de células é ultrapassado, garantindo uma cobrança justa e precisa.
      </Typography>

      <Stack direction="row" spacing={0} alignItems="center" justifyContent="flex-start" flexWrap="wrap" sx={{ mt: 5 }}>
        {plan && plan.tiers && plan.tiers.map((item, index) => (
          <SubscriptionTier item={item} key={index} />
        ))}
      </Stack>

      <Container sx={{ display: 'flex', justifyContent: 'end', mt: 5 }}>
        <LoadingButton
          size="large"
          variant="contained"
          color="primary"
          loading={loading}
          onClick={() => createSubscription()}
          sx={{ minWidth: '150px' }}>
          Assinar
        </LoadingButton>
      </Container>
    </>
  );
}

export default SubscriptionContent;