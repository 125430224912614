import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Box, Container, Stack } from '@mui/material';
import Logo from '../components/logo';
import { LoginForm } from '../sections/auth/login';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: '80vw',
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function LoginPage() {
  const [queryParameters] = useSearchParams()
  const email = queryParameters.get('email');

  return (
    <>
      <Helmet>
        <title> Login | CélulaHub </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Container>
          <StyledContent>
            <Stack direction={{ sm: 'column', md: 'row' }} spacing={{ xs: 3, sm: 3, md: 4 }}>
              <Box
                component="img"
                src="/assets/illustrations/illustration_login.svg"
                sx={{ 
                  width: { sm: '100%', md: '50%' },
                  maxHeight: 350, 
                  mx: 'auto' 
                }}
              />
              <LoginForm email={email} />
            </Stack>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
