import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Checkbox, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import useNetwork from '../../../hooks/useNetwork';

const StyledContent = styled('div')(({ theme }) => ({
  minWidth: 300,
  [theme.breakpoints.up('md')]: {
    minWidth: 450,
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: 600,
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: 750,
  },
  justifyContent: 'start',
  textAlign: 'start',
}));

AttendanceStep.propTypes = {
  report: PropTypes.object,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  groupId: PropTypes.string,
};

const ATTENDANCE_STATE = {
  list: [],
};

export default function AttendanceStep(props) {
  const [attendance] = React.useState(ATTENDANCE_STATE);
  const [rows, setRows] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const { request } = useNetwork();

  // TODO: this is not showing selected members because it should have ids in the list instead of names

  const handleClick = (_, name) => {
    if (props.readOnly) {
      return;
    }

    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
    attendance.list = newSelected;

    props.onChange('attendance', newSelected.join(', ').substring(1).trim());
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  React.useEffect(() => {
    request(`v1/groups/${props.groupId}/people`, 'GET').then((response) => {
      setRows(response.data.people);
    });

    if (props.report !== null && props.report.attendance !== undefined) {
      const list = props.report.attendance.split(', ');
      setSelected(list);
      attendance.list = list;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <>
      <StyledContent>
        <Stack direction="column" spacing={4}>
          <TableContainer component={Paper}>
            <Table aria-label="attendance">
              <TableBody>
                {rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `attendance-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {`${row.firstName} ${row.lastName}`}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </StyledContent>
    </>
  );
}
