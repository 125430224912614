import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { Button, Card, IconButton, Modal, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const StyledContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: theme.spacing(4),
}));

const StyledCloseButton = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(4),
  top: theme.spacing(4),
}));

CustomModal.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  canDismiss: PropTypes.bool,
};

CustomModal.defaultProps = {
  canDismiss: true,
}

export default function CustomModal(props) {
  const theme = useTheme();

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {
          if (props.canDismiss) {
            props.onClose(false)
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '70vw',
          transform: 'translate(-50%, -50%)',
          [theme.breakpoints.down('sm')]: {
            top: '0%',
            left: '0%',
            width: '100vw',
            height: '100vh',
            transform: 'translate(0%, 0%)',
          },
          boxShadow: 16,
          p: 2,
        }}>
          <StyledContent>
            {props.title !== undefined && <Typography variant="h3" sx={{ mb: 4 }}>
              {props.title}
            </Typography>}
            <StyledCloseButton>
              <IconButton aria-label="close" onClick={() => {
                if (props.canDismiss) {
                  props.onClose(false)
                }
              }}>
                <CloseIcon />
              </IconButton>
            </StyledCloseButton>
            <Stack direction="column" spacing={2}>
              {props.children}
            </Stack>
            {props.action !== undefined && <Button variant="contained" sx={{ mt: 4, alignSelf: 'flex-end' }} onClick={props.onAction}>
              {props.action}
            </Button>}
          </StyledContent>
        </Card>
      </Modal>
    </>
  );
}