import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs';
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import 'dayjs/locale/pt';
import { ptBR } from '@mui/x-date-pickers/locales';
import { useLocalStorage } from '@uidotdev/usehooks';

import { DropdownMenu } from '../sections/DropdownMenu';
import { useGroupClient } from '../hooks/network/useGroupClient';
import { fTime } from '../utils/formatTime';
import CustomModal from '../components/CustomModal';

const TYPES = [
  { value: 'SUPERGROUP', label: 'Supervisão' },
  { value: 'MIXED', label: 'Mista' },
  { value: 'MALE', label: 'Masculina' },
  { value: 'FEMALE', label: 'Feminina' },
  { value: 'KIDS', label: 'Infantil' },
];

const WEEKDAYS = [
  { value: 'SUNDAY', label: 'Domingo' },
  { value: 'MONDAY', label: 'Segunda' },
  { value: 'TUESDAY', label: 'Terça' },
  { value: 'WEDNESDAY', label: 'Quarta' },
  { value: 'THURSDAY', label: 'Quinta' },
  { value: 'FRIDAY', label: 'Sexta' },
  { value: 'SATURDAY', label: 'Sábado' },
];

export const GroupDetailModal = ({ mode, group, parentId, open, onClose }) => {
  const [name, setName] = React.useState('');
  const [weekday, setWeekday] = React.useState('WEDNESDAY');
  const [time, setTime] = React.useState(dayjs());
  const [type, setType] = React.useState('MIXED');
  const [leader, setLeader] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [multiplication, setMultiplication] = React.useState('');
  const [me] = useLocalStorage('me', {});
  const { createGroup, updateGroup } = useGroupClient();

  const portugueseLocale = ptBR.components.MuiLocalizationProvider.defaultProps.localeText;

  const parseTime = (time) => {
    if (time === undefined) {
      return '';
    }

    const comps = time.split(':');
    return dayjs().hour(comps[0]).minute(comps[1]);
  };

  const handleChange = (input, value) => {
    if (value === undefined) {
      return;
    }

    switch (input) {
      case 'name':
        setName(value);
        break;
      case 'address':
        setAddress(value);
        break;
      case 'weekday':
        setWeekday(value);
        break;
      case 'time':
        setTime(value);
        break;
      case 'leader':
        setLeader(value);
        break;
      case 'type':
        setType(value);
        break;
      case 'nextMultiplication':
        setMultiplication(dayjs(value));
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    if (group !== undefined && mode !== 'create') {
      const stateGroup = group;
      setName(stateGroup.name);
      setAddress(stateGroup.address);

      if (stateGroup.weekday !== undefined) {
        setWeekday((WEEKDAYS.find((e) => e.value === stateGroup.weekday)));
      }

      if (stateGroup.time !== undefined) {
        setTime(parseTime(stateGroup.time));
      }

      if (stateGroup.type !== undefined) {
        setType((TYPES.find((e) => e.value === stateGroup.type)));
      }

      setMultiplication(stateGroup.nextMultiplication);
    } else {
      setTime(parseTime('20:00'));
      setType(TYPES[1]);
      setWeekday(WEEKDAYS[3]);
      setMultiplication(dayjs().add(12, 'month'));
    }
  }, [group, mode]);

  const onComplete = React.useCallback(async () => {
    const groupObj = { name, address, weekday: weekday.value, time: fTime(time), type: type.value, leaderEmail: leader, nextMultiplication: multiplication };
    
    try {
      if (mode === 'create') {
        const fromSupergroup = me.role === 'SUPERVISOR';
        await createGroup(parentId, groupObj, fromSupergroup);
        onClose(true, 'Célula criada com sucesso');
      } else {
        await updateGroup(group.id, groupObj);
        onClose(true, 'Célula atualizada com sucesso');
      }
    } catch (error) {
      let message = 'Não foi possível salvar a célula';

      const { message: errorMsg } = error.error;
      if (errorMsg !== undefined) {
        message = errorMsg;
      }

      onClose(false, message);
    }
  }, [parentId, leader, type, mode, onClose, createGroup, updateGroup, me.role, address, multiplication, name, time, weekday.value, group]);

  const getHeading = () => {
    switch (mode) {
      case 'edit':
        return 'Editar célula';
      case 'create':
        return 'Criar célula';
      default:
        return '';
    }
  }

  return (
    <>
      <Helmet>
        <title>CélulaHub</title>
      </Helmet>

      <CustomModal
        title={getHeading()}
        action='Salvar'
        open={open}
        onClose={() => onClose()}
        onAction={onComplete}
      >
        <TextField
          id="name"
          fullWidth
          label="Nome"
          variant="outlined"
          value={name}
          onChange={(newValue) => handleChange('name', newValue.target.value)}
        />
        <TextField
          id="address"
          fullWidth
          label="Endereço"
          value={address}
          variant="outlined"
          onChange={(newValue) => handleChange('address', newValue.target.value)}
        />
        {mode === 'create' && <TextField
          id='leader'
          fullWidth
          label="Email do líder"
          value={leader}
          variant='outlined'
          onChange={(newValue) => handleChange('leader', newValue.target.value)}
        />}
        <DropdownMenu
          items={TYPES}
          defaultValue={type}
          onChange={(newValue) => handleChange('type', newValue)}
        />
        <DropdownMenu
          items={WEEKDAYS}
          defaultValue={weekday}
          onChange={(newValue) => handleChange('weekday', newValue)}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt" localeText={portugueseLocale}>
          <TimePicker label="Horário" value={time} onChange={(newValue) => handleChange('time', newValue)} />

          <DatePicker
            label="Data de multiplicação"
            value={dayjs(multiplication)}
            views={['year', 'month', 'day']}
            onChange={(newValue) => handleChange('nextMultiplication', newValue)}
          />
        </LocalizationProvider>
      </CustomModal>
    </>
  );
}

GroupDetailModal.propTypes = {
  mode: PropTypes.string,
  parentId: PropTypes.string,
  group: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
