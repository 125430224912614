import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Button, Card, Container, Typography } from '@mui/material';

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '90vh',
  width: '90vw',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4, 0),
}));

const SubscriptionSuccessPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>CélulaHub</title>
      </Helmet>

      <StyledContent>
        <Card sx={{ p: 4 }}>
          <>
            <Typography variant="h3">Sua assinatura foi realizada com sucesso</Typography>
            <Typography variant="body1" paragraph sx={{ mt: 4 }}>Agora você tem acesso total ao nosso sistema. Convidamos você a explorar todas as funcionalidades disponíveis e começar a criar suas células imediatamente. Aproveite ao máximo todas as ferramentas e recursos que oferecemos para otimizar o gerenciamento das suas células e alcançar seus objetivos.</Typography>

            <Typography variant="body1" paragraph>Seja bem-vindo e sinta-se à vontade para descobrir tudo o que nosso aplicativo tem a oferecer!</Typography>

            <Container sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => navigate('/')}
                sx={{ minWidth: '150px' }}>
                Página inicial
              </Button>
            </Container>
          </>
        </Card>

      </StyledContent>
    </>
  );
}

export default SubscriptionSuccessPage;