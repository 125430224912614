export const formatSnackbarMessage = (model, mode, type, success) => {
  let title = '';
  let article = '';
  if (model === 'report') {
    title = 'Relatório';
    article = 'o';
  } else if (model === 'person') {
    title = 'Participante';
    article = 'o';
  } else {
    title = 'Célula';
    article = 'a';
  }

  let message;
  if (mode !== 'edit') {
    if (success === true) {
      message =
        type === 'create'
          ? `${title} criad${article} com sucesso`
          : `${title} atualizad${article} com sucesso`;
    } else {
      message =
        type === 'create'
          ? `Não foi possível criar ${article} ${title}, tente novamente.`
          : `Não foi possível salvar ${article} ${title}, tente novamente.`;
    }
  }

  return message;
}