import React from 'react';
import { Container, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import useNetwork from '../../hooks/useNetwork';

const StyledContent = styled('div')(({ theme }) => ({
  minWidth: 300,
  [theme.breakpoints.up('md')]: {
    minWidth: 450,
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: 600,
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: 750,
  },
  margin: 'auto',
  minHeight: '70vh',
  display: 'flex',
  flexDirection: 'column',
}));

export default function SettingsPlanContent() {
  const [plan, setPlan] = React.useState({});
  const { request } = useNetwork();

  const onFetch = React.useCallback(async () => {
    try {
      const response = await request(`v1/subscriptions`, 'GET');
  
      console.log(response.data[0]);
  
      setPlan(response.data[0]);
    } catch (error) {
      console.log(error);
    }
  }, [plan]);

  React.useEffect(() => {
    onFetch();
  }, []);

  return (
    <>
      <Container>
        <StyledContent>
          <Typography variant="h3" paragraph>Plano e pagamento</Typography>
          <Typography variant="body1" paragraph>Gerencie suas informações de faturamento, pagamento e assinatura</Typography>
        </StyledContent>
      </Container>
    </>
  );
}