import * as React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Card, Grid, TableCell } from '@mui/material';
import EmptyState from '../reports/overview/EmptyState';
import PaginatedTable from '../../components/table/PaginatedTable';

import SummaryBox from '../reports/overview/SummaryBox';
import Row from './Row';

import { mapMood } from '../reports/overview/MeetingUtil';
import { fDate } from '../../utils/formatTime';
import useNetwork from '../../hooks/useNetwork';
import { GroupInfo } from '../../components/GroupInfo';
import { GroupDetailModal } from '../../pages/GroupDetailModal';

const StyledContent = styled('div')(({ theme }) => ({
  minWidth: 300,
  [theme.breakpoints.up('md')]: {
    minWidth: 450,
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: 600,
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: 750,
  },
  justifyContent: 'start',
  textAlign: 'start',
}));

SupergroupOverviewTab.propTypes = {
  groupId: PropTypes.string
}

export default function SupergroupOverviewTab(props) {
  const [open, setOpen] = React.useState(false);
  const [shouldUpdate, setShouldUpdate] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [data, setData] = React.useState({});
  const { request } = useNetwork();

  const nextMultiplication = () => {
    if (data.nextGroupToMultiply === null || data.nextGroupToMultiply === undefined) {
      return { date: '', group: '' };
    }

    return {
      date: data.nextGroupToMultiply.date !== null ? fDate(data.nextGroupToMultiply.date) : 'A definir',
      group: data.nextGroupToMultiply.name !== null ? data.nextGroupToMultiply.name : '',
    };
  };

  const fetchGroupInfo = React.useCallback(async () => {
    try {
      if (props.groupId !== null && props.groupId !== undefined && props.groupId !== '') {
        const response = await request(`v1/groups/${props.groupId}/children/overview`, 'GET');
        setData(response.data);
        const { groups, count } = response.data.children;
        setRows(groups);
        setTotalCount(count);
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  React.useEffect(() => {
    if (shouldUpdate) {
      fetchGroupInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdate]);

  const onClose = (updated) => {
    setShouldUpdate(updated);
    setOpen(false);
  };

  return (
    <>
      <StyledContent>
      <GroupInfo name={data.group !== undefined ? data.group.name : ''} onClick={() => setOpen(true)} />
        <Grid container spacing={3} sx={{ mt: 0 }}>
          <Grid item xs={12} md={4}>
            <SummaryBox title="Número de células" content={data.groupCount} />
          </Grid>
          <Grid item xs={12} md={4}>
            <SummaryBox title="Presença de Deus Média" content={mapMood(data.mood)} />
          </Grid>
          <Grid item xs={12} md={4}>
            <SummaryBox
              title="Próxima célula a multiplicar"
              content={`${nextMultiplication().group} - `}
              additionalContent={nextMultiplication().date}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Card>
              {data === undefined && <EmptyState title="Nenhum conteúdo disponível no momento " />}
              {data !== undefined && (
                <PaginatedTable
                  rows={rows}
                  totalCount={totalCount}
                  onPaginate={(page) => fetchGroupInfo(page)}
                  onClickRow={() => {}}
                  provideBaseRow={false}
                  head={
                    <>
                      <TableCell>Nome</TableCell>
                      <TableCell>Líder</TableCell>
                      <TableCell>Tipo</TableCell>
                      <TableCell>Próxima Multiplicação</TableCell>
                    </>
                  }
                  body={(row) =>
                    <>
                      <Row key={row.id} row={row} />
                    </>
                  }
                />
              )}
            </Card>
          </Grid>
        </Grid>
      </StyledContent>

      <GroupDetailModal mode='edit' group={data.group} parentId={data.id} open={open} onClose={(updated) => onClose(updated)} />
    </>
  );
}
