import PropTypes from 'prop-types';
import { Container, Link, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAuth from '../../../hooks/useAuthentication';

LoginForm.propTypes = {
  email: PropTypes.string
}

export default function LoginForm(props) {
  const { login } = useAuth();

  return (
    <Container
      sx={{ width: { sm: '100%', md: '60%' }}}>
      <Typography variant="h3">Bem vindo ao CélulaHub</Typography>
      <Typography variant="body1" paragraph sx={{ mt: 2, mb: 6 }}>Faça login para acessar sua conta e aproveitar todas as funcionalidades do nosso aplicativo.</Typography>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={() =>
          login({
            authUrlParams: {
              login_hint: props.email,
              lang: "pt-BR"
            }
          })}>
        Entrar
      </LoadingButton>

      <Link
        variant="subtitle2"
        underline="hover"
        onClick={() => window.open(process.env.REACT_APP_REQUEST_ACCESS_URL, '_self')}
        sx={{ width: '100%', mt: 3, display: 'flex',
        justifyContent: 'center' }}>
        Solicitar acesso
      </Link>
    </Container>
  );
}
