import * as React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet-async';

import { Button, Stack, Step, StepLabel, Stepper } from '@mui/material';
import CalendarStep from '../sections/reports/detail/CalendarStep';
import AttendanceStep from '../sections/reports/detail/AttendanceStep';
import InfoStep from '../sections/reports/detail/InfoStep';
import CustomModal from '../components/CustomModal';
import useNetwork from '../hooks/useNetwork';

const steps = ['Data e modalidade', 'Informações', 'Lista de presença'];

const REPORT_STATE = {
  id: '',
  date: '',
  type: '',
  lesson: '',
  teacher: '',
  comment: '',
  visit: false,
  mood: '',
  attendance: '',
};

ReportDetailModal.propTypes = {
  mode: PropTypes.string,
  report: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  groupId: PropTypes.string,
}

export default function ReportDetailModal(props) {
  const [step, setStep] = React.useState(0);
  const [nextLabel, setNextLabel] = React.useState('Próximo');
  const [report, setReport] = React.useState({ ...REPORT_STATE });
  const [nextDisabled, setNextDisabled] = React.useState(true);
  const [groupId, setGroupId] = React.useState('');
  const [readOnly, setReadOnly] = React.useState(false);

  const { request } = useNetwork();

  const nextStep = () => {
    if (report.type === 'NONE') {
      onComplete();
    } else {
      setNextLabel('Próximo');
    }

    if (step < 2 && report.type !== 'NONE') {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleChange = (input, value) => {
    report[input] = value;
    setNextDisabled(step === 0 && report.date === '');
    if (input === 'type') {
      if (value === 'NONE') {
        setNextLabel('Salvar');
      } else {
        setNextLabel('Próximo');
      }
    }
  };

  const clearReport = React.useCallback(() => {
    setReport({ ...REPORT_STATE });
  }, []);

  React.useEffect(() => {
    if (props.report !== undefined) {
      setReport(props.report);
      setNextDisabled(false);
    }

    if (props.groupId !== undefined) {
      setGroupId(props.groupId);
    } else if (props.report !== undefined && props.report.groupId !== undefined) {
      setGroupId(props.report.groupId);
    }

    setReadOnly(props.mode === 'view');
  }, [report, props, nextDisabled]);

  const onComplete = React.useCallback(async () => {
    if (readOnly) {
      setStep(0);
      props.onClose();
      return;
    }

    if (report.type === '') {
      report.type = 'IN_PERSON';
    }

    if (report.mood === '') {
      report.mood = 'NEUTRAL';
    }

    try {
      if (props.mode === 'create') {
        report.id = crypto.randomUUID();
        await request(`v1/groups/${groupId}/reports`, 'POST', report);
      } else {
        await request(`v1/reports/${report.id}`, 'PUT', report);
      }

      setStep(0);
      props.onClose(true);
    } catch (error) {
      console.log(`error: ${JSON.stringify(error)}`);
      props.onClose(false);
    }

    clearReport();
  }, [report, clearReport, request, groupId, props, readOnly]);

  return (
    <>
      <Helmet>
        <title>CélulaHub</title>
      </Helmet>

      <CustomModal
        open={props.open}
        onClose={() => {
          setStep(0);
          props.onClose();
        }}>

        <Stepper activeStep={step} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Stack direction="column" spacing={4} sx={{ pt: 6 }}>
          {step === 0 && <CalendarStep key={report.key} report={report} onChange={handleChange} readOnly={readOnly} />}
          {step === 1 && <InfoStep key={report.key} report={report} onChange={handleChange} readOnly={readOnly} />}
          {step === 2 && <AttendanceStep key={report.key} groupId={groupId} report={report} readOnly={readOnly} onChange={handleChange} />}

          <Stack direction="row" spacing={1} flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end">
            {step > 0 && (
              <Button variant="contained" onClick={prevStep}>
                Anterior
              </Button>
            )}
            {step < 2 && (
              <Button variant="contained" disabled={nextDisabled} onClick={nextStep}>
                {nextLabel}
              </Button>
            )}
            {step === 2 && (
              <Button variant="contained" onClick={onComplete}>
                {readOnly ? 'Fechar' : 'Salvar'}
              </Button>
            )}
          </Stack>
        </Stack>

      </CustomModal>
    </>
  );
}
